import Component from './Component.jsx';

function App() {
  return (
    <div className="App">
      <Component />
    </div>
  );
}

export default App;
