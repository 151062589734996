"use client"

import { useState, useEffect } from "react"
import { Button, Input, IconButton } from "@chakra-ui/react"
import { SunIcon, MoonIcon } from "@chakra-ui/icons"
import { Construction } from "lucide-react"

export default function Component() {
  const [isDarkTheme, setIsDarkTheme] = useState(true)

  useEffect(() => {
    if (isDarkTheme) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [isDarkTheme])

  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme)
  }

  return (
    <div className={`flex flex-col items-center justify-center min-h-screen p-4 transition-colors duration-200 ${
      isDarkTheme ? 'bg-slate-900' : 'bg-gradient-to-br from-blue-100 via-cyan-200 to-sky-100'
    }`}>
      <IconButton
        aria-label="Toggle theme"
        icon={isDarkTheme ? <SunIcon color="yellow.300" /> : <MoonIcon color="blue.600" />}
        size="lg"
        position="absolute"
        top="4"
        right="4"
        onClick={toggleTheme}
        bg="transparent"
        border="none"
      />
      
      <main className="text-center space-y-8 max-w-2xl mx-auto">
      <h1 className={`text-6xl font-bold bg-clip-text text-transparent bg-gradient-to-r ${
        isDarkTheme ? 'from-blue-400 to-cyan-600' : 'from-blue-600 to-cyan-600'
      } leading-tight`}>
        OTT Agency
      </h1>

        
        <div className={`flex items-center justify-center space-x-2 text-2xl font-semibold ${
          isDarkTheme ? 'text-yellow-300' : 'text-yellow-600'
        }`}>
          <Construction className="w-8 h-8" />
          <span>Under Construction</span>
        </div>
        
        <p className={`text-xl ${isDarkTheme ? 'text-gray-300' : 'text-gray-600'}`}>
          "OTT is everywhere. A network that connects, empowers, and reaches beyond boundaries.
          Wherever you are, you’re part of something bigger."
        </p>
        
        <div className="space-y-4 max-w-md mx-auto">
          <div className="flex space-x-2">
            <Input 
              type="email" 
              placeholder="Enter your email" 
              size="lg"
              variant="filled"
              focusBorderColor={isDarkTheme ? "cyan.600" : "blue.600"}
              bg={isDarkTheme ? "gray.700" : "white"}
              color={isDarkTheme ? "white" : "black"}
            />
            <Button 
              size="lg"
              bgGradient="linear(to-r, blue.500, cyan.600)" 
              _hover={{ bgGradient: "linear(to-r, blue.600, cyan.700)" }} 
              color="white"
            >
              Notify Me
            </Button>
          </div>
        </div>
        
        <p className={`text-sm ${isDarkTheme ? 'text-gray-400' : 'text-gray-500'}`}>
          Be the first to know when we launch OTT!
        </p>
      </main>
      
      <footer className={`mt-16 text-center ${isDarkTheme ? 'text-gray-400' : 'text-gray-600'}`}>
        <p className="text-sm">© 2024 OTT Agency. All rights reserved.</p>
        <div className="mt-2 space-x-4">
          {/* Boutons stylisés comme des liens, mais sans href */}
          <button className="text-sm hover:underline text-inherit cursor-pointer" onClick={() => alert('Privacy Policy')}>
            Privacy Policy
          </button>
          <button className="text-sm hover:underline text-inherit cursor-pointer" onClick={() => alert('Terms of Service')}>
            Terms of Service
          </button>
        </div>
      </footer>
    </div>
  )
}
